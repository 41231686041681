import { useCallback } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Button from "antd/es/button";
import tableExport from "antd-table-export";

import { ResponseType, TableProps, starEquivalentDescriptions } from "./types";
import { ICombo, ILookupKeys } from "components/api";


const Table = (props: TableProps) => {
  const { isLoading, total, pageSize, dataSource, SendRequestForData } = props;
  const columns = useCallback(
    (
      lookups: Record<ILookupKeys, ICombo[]>,
      isLoading: boolean
    ): ProColumns<ResponseType>[] => {
      if (isLoading) {
        return [];
      }
      return [
        {
          title: "Branch",
          dataIndex: "branch",
          valueType: "select",
          request: async () => lookups["branches"],
          render: (_, record) => record?.branch?.name,
          // hideInTable: true,
          order: 1,
          debounceTime: 0,
          fieldProps: {
            showSearch: true,
          },
        },
        {
          title: "Hub",
          dataIndex: "hub",
          valueType: "select",
          request: async () => lookups["hubs"],
          render: (_, record) => record?.hub?.name,
          hideInTable: true,
          order: 1,
          debounceTime: 0,
          fieldProps: {
            showSearch: true,
          },
        },
        {
          title: "Staff",
          dataIndex: "ro",
          valueType: "select",
          request: async () => lookups["ros"],
          render: (_, record) => record?.ro?.name,
          order: 1,
          debounceTime: 0,
          fieldProps: {
            showSearch: true,
          },
        },
        {
          title: "Phone",
          dataIndex: "accountNo",
          valueType: "text",
          hideInSearch: true,
        },
        {
          title: "Rate",
          dataIndex: "rate",
          valueType: "rate",
          fieldProps: {
            allowHalf: false,
            tooltips: starEquivalentDescriptions
          },
          render: (dom, { rate }) => {
            return (
              <>
                {dom}
                {"  " + starEquivalentDescriptions[rate - 1]}
              </>
            )
          }
        },
        {
          title: "Note",
          dataIndex: "note",
          valueType: "text",
          hideInSearch: true,
          render: (_, { note, note2 }) => note === "Other" ? note2 : note
        },

        {
          title: "Date",
          dataIndex: "date",
          valueType: "date",
          order: 5,
        },
      ];
    },
    []
  );
  const renderedColumns = columns(props.lookups.data, props.lookups.isLoading);

  const exportInstance = new tableExport(
    dataSource.map((v) => ({
      ...v,
      ro: v?.ro?.name,
      hub: v?.hub?.name,
      branch: v?.branch?.name,
      date: new Date(v.date).toLocaleDateString(),
    })),
    renderedColumns
      // remove options, deleted and notes columns
      .filter((x) => x.dataIndex !== "Operations" && x.dataIndex !== "deleted" && x.dataIndex !== "notes")
      .map((x) => ({
        dataIndex: x.dataIndex as string,
        title: x.title! as string,
      }))
  );

  return (
    <ProTable<ResponseType>
      headerTitle="Feedback List"
      columns={renderedColumns}
      loading={isLoading}
      request={async (params = {}, sort, filter) =>
        SendRequestForData({ params, sort, filter })
      }
      onReset={() => {
        let params = { current: 1, pageSize };
        let sort = {};
        let filter = {};
        SendRequestForData({ params, sort, filter, isReset: true });
      }}
      dataSource={dataSource}
      rowKey="id"
      pagination={{
        showQuickJumper: true,
        pageSize,
        total,
        pageSizeOptions: ["10", "50", "100", "200", "300", "400", "500"],
      }}
      search={{ labelWidth: "auto" }}
      dateFormatter="string"
      // options={false}
      toolBarRender={() => [
        <Button
          type="primary"
          onClick={() => exportInstance.download("Feedbacks List", "xlsx")}
        >
          Export
        </Button>,
      ]}

    />
  );
};

export default Table;
